<template>
  <div id="custom-modal" class="custom-modal modal-fullscreen">
    <div id="custom-modal-body bg-transparent" class="custom-modal-body shadow">
      <div
        class="
          bg-primary
          text-light
          p-3
          d-flex
          justify-content-between
          align-items-center
        "
      >
        <button class="btn btn-sm btn-light text-primary" @click="download()">
          <i class="fas fa-file-download text-primary" />
        </button>
        {{ assessment.title }}
        <button class="btn py-0" @click="close()">
          <i class="fas fa-times text-light" />
        </button>
      </div>
      <div class="input-group py-2">
        <span class="input-group-text bg-white border-0">
          <i class="fas fa-search" />
        </span>
        <input
          type="text"
          class="form-control border-0 ps-0 no-focus"
          :class="{ 'border-end-0': search.length > 0 }"
          placeholder="Search by student name or nric"
          v-model="search"
        />
        <button
          class="btn bg-white border border-light border-0"
          type="button"
          v-if="search.length > 0"
          @click="search = ''"
        >
          &times;
        </button>
      </div>
      <div
        class="
          table-responsive
          rounded
          shadow-sm
          rounded
          border border-light
          position-relative
        "
      >
        <table class="table lh-sm table-admin w-100">
          <thead>
            <tr>
              <th class="pe-0 text-center" style="width: 1%">#</th>
              <th>Name</th>
              <th class="text-center" style="width: 1%">MYKID/NRIC</th>
              <th class="text-center" style="width: 1%">Age</th>
              <th class="text-center" style="width: 1%">Marks</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(submission, index) in computedSubmissions"
              :key="submission._id"
            >
              <td style="width: 1%" class="pe-0">{{ index + 1 }}</td>
              <td class="text-nowrap text-truncate">
                {{ submission.dependant.name }}
              </td>
              <td
                class="text-nowrap text-truncate text-center"
                style="width: 1%"
              >
                {{ submission.dependant.id_no }}
              </td>
              <td
                class="text-nowrap text-truncate text-center"
                style="width: 1%"
              >
                {{ Helper.getAge(submission.dependant.date_of_birth) }}
              </td>
              <td class="text-nowrap text-center" style="width: 1%">
                <span
                  class="badge border fw-normal fs-6"
                  :class="
                    assessmentMarks(submission) !== null
                      ? assessmentMarks(submission) >= 80
                        ? 'bg-success'
                        : 'bg-warning'
                      : 'bg-light text-muted'
                  "
                >
                  {{ assessmentMarks(submission) || 0 }}%
                </span>
              </td>
            </tr>
            <tr v-if="computedSubmissions.length < 1">
              <td colspan="5" class="text-center fst-italic text-muted">
                No submission found
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <loading-spinner class="position-absolute text-light py-5" v-if="isLoading">
      Loading submission list...
    </loading-spinner>
  </div>
</template>

<script>
import XLSX from "xlsx";
import LoadingSpinner from "@/components/LoadingSpinner";

export default {
  components: { LoadingSpinner },
  props: ["assessment"],
  data() {
    return {
      submissions: [],
      isLoading: false,
      search: "",
    };
  },
  computed: {
    computedSubmissions() {
      const search = this.search.toLowerCase();

      return this.submissions.filter(
        (submission) =>
          submission.dependant.name.toLowerCase().includes(search) ||
          (submission.dependant.id_no &&
            submission.dependant.id_no.includes(search))
      );
    },
    assessmentMarks() {
      return (submission) => {
        return submission ? submission.marks || 0 : null;
      };
    },
  },
  methods: {
    async download() {
      let rows = [];

      this.submissions.forEach((submission, index) => {
        let data = {
          No: index + 1,
          Name: submission.dependant.name,
          NRIC: submission.dependant.id_no,
        };

        submission.answers.forEach((answer, answerIndex) => {
          data[`Question ${answerIndex + 1}`] =
            answer.answerSequence.toUpperCase();
        });

        data["Marks"] = `${submission.marks || 0}%`;

        rows.push(data);
      });

      const ws = XLSX.utils.json_to_sheet(rows, {});

      const wbb = XLSX.utils.book_new();

      XLSX.utils.book_append_sheet(wbb, ws, "Student Assessment");

      XLSX.writeFile(wbb, `${this.assessment.title}.xlsx`);
    },
    async getSubmissions() {
      this.isLoading = true;

      try {
        const { data } = await this.API.get(
          `dependant-assessments?_limit=-1&assessment=${this.assessment._id}`
        );

        this.submissions = data;
      } catch (error) {
      } finally {
        this.isLoading = false;
      }
    },
    close(e) {
      document.body.classList.remove("overflow-hidden");

      this.$emit("close", e);
    },
  },
  mounted() {
    document.body.classList.add("overflow-hidden");

    this.getSubmissions();
  },
};
</script>